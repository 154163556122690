<template>
  <section id="user-management">
    <Table 
      :loading="loading" 
      :page="page"
      :pageCount="pageCount"
      :filter="filter"
      :totalCount="totalCount"
      :paginate="paginate"
      @search="(e) => search = e"
      @paginate="(e) => paginate = e" 
      @page="(e) => page = e" 
      @getUsers="getUsers"
      @resetFilters="resetFilters"
      :users="users"/>
  </section>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import Table from '../../components/admin/user/Table.vue'
export default {
  components: { Table },
  data: () => ({
    loading: true,
    paginate: '10',
    page: 1,
    pageCount: 1,
    totalCount: 0,
    users: [],
    search: '',
    filter: {
      role: '',
      class_category_id: [],
      course_id: [],
      status: '',
      created_at: ''
    }
  }),
  computed: {
    ...mapState('admin', {
      // users: (state) => {
      //   state.users.map(user => {
      //     user.name = `${(user.first_name ? user.first_name : user.role)+ ' ' + (user.last_name ? user.last_name : 'Account') +' ' + (user.suffix ? user.suffix : '')}`
      //   })

      //   return state.users
      // },
      userState: (state) => state.users,
      filteredUsers: (state) => state.filtered_users,

      // getMiddleInitials(_middle_name) {
      //   let initials = []
      //   let _names = _middle_name.split(' ')
      //   _names.forEach(item => {
      //     initials.push(item[0])
      //   })
      //   let _middle_initial = initials.join('. ')
      //   return  `${_middle_initial}.`
      // }
    }),
  },
  created() {
    if(Object.values(this.$route.query).length == 0){
      this.$router.replace({ query: { page: 1, paginate: this.paginate, timestamp: Date.now() } });
    } else {
      let { page, paginate, role, status, created_at } = this.$route.query

      if (page) {
        this.page = Number(page)
      } 
      if (paginate) {
        this.paginate = paginate
      }
      if (role) {
        this.filter.role = role
      }
      if (status) {
        this.filter.status = status
      }
      if (created_at) {
        this.filter.created_at = created_at
      }
    }
    this.getUsers()
  },
  methods: {
    ...mapActions('admin', [
      'getUsersAction',
      'getRegistrationTablesAction'
    ]),
    ...mapMutations(['alertMutation']),

    resetFilters() {
      this.filter = {
        role: '',
        class_category_id: [],
        course_id: [],
        status: '',
        created_at: ''
      }
      this.$router.replace({ query: { page: this.$route.query.page, paginate: this.$route.query.paginate, timestamp: Date.now() } })
      this.getUsers()
    },

    getUsers(){
      this.loading = true
      this.getUsersAction({
        page: this.page, 
        paginate: Number(this.paginate),
        role: this.filter.role,
        course_id: this.filter.course_id,
        status: this.filter.status,
        created_at: this.filter.created_at,
        search: this.search
      }).then(res => {
        this.users = res.data
        this.pageCount = res.last_page
        this.loading = false
        this.totalCount = res.total
        this.users.map(user => {
          user.name = `${(user.first_name ? user.first_name : user.role)+ '' + (user.middle_name ? ` ${user.middle_name}` : '') + '' + (user.last_name ? ` ${user.last_name}` : 'Account') +' ' + (user.suffix ? user.suffix : '')}`
        })
      }).catch(() => {
        this.loading = false
        this.users = 0
        this.pageCount = 0
        this.totalCount = 0
        this.alertMutation({
          show: true,
          text: 'Something went wrong',
          type: "error"
        })
      })
    }
  },
  watch:{
    page(val){
      this.$router.replace({ query: { ...this.$route.query, page: val, timestamp: Date.now() } });
      this.getUsers()
    },
    paginate(val){
      this.page = 1
      this.$router.replace({ query: { ...this.$route.query, paginate: val, timestamp: Date.now() } });
      this.getUsers()
    },

    search(){

      if(this.page !== 1) {
        this.page = 1
      } else {
        this.getUsers()
      }
    },

    userState() {
      this.getUsers()
    }
  }
}
</script>